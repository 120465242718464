import React from 'react';
import { MASONRY, createLiveChatClient } from '@leagueplatform/highmark-common';
import { getClaimsConfig } from 'utils/get-claims-config.util';

export const AboutMeRoutes = React.lazy(() =>
  import('@leagueplatform/about-me').then((module) => {
    module.leagueAboutMe.config({
      showAccountBanner: false,
      showLandingPageBackButton: true,
    });
    return {
      default: module.AboutMeRoutes,
    };
  }),
);

export const WalletRoutes = React.lazy(() =>
  import('@leagueplatform/wallet').then((module) => {
    // Config init is done here to stop the main App.tsx init from importing this SDK
    // which would cause the SDK to be bundled into the main chunk
    module.leagueWallet.config({
      showLandingPageHeaderBranding: true,
      showLandingPageClaimsEntry: true,
    });

    return { default: module.WalletRoutes };
  }),
);

export const AchievementsRoutes = React.lazy(() =>
  import('@leagueplatform/rewards-achievements').then((module) => ({
    default: module.AchievementsRoutes,
  })),
);

export const HealthProfileRoutes = React.lazy(() =>
  import('@leagueplatform/health-profile').then((module) => {
    // Config init is done here to stop the main App.tsx init from importing this SDK
    // which would cause the SDK to be bundled into the main chunk
    module.leagueHealthProfile.config({
      ratingsBarEnabled: false,
      careSdkEnabled: true,
      chatWithProfessionalEnabled: false,
    });

    return { default: module.HealthProfileRoutes };
  }),
);

export const CareCollectionsRoutes = React.lazy(() =>
  import('@leagueplatform/care-collections').then((module) => ({
    default: module.CareCollectionsRoutes,
  })),
);

export const CareDiscoveryRoutes = React.lazy(() =>
  import('@leagueplatform/care-discovery').then((module) => {
    module.leagueCareDiscovery.config({
      masonryAppId: {
        left: MASONRY.APP_ID_CARE_DISCOVERY_HOME,
      },
    });

    return { default: module.CareDiscoveryRoutes };
  }),
);

export const ClaimsRoutes = React.lazy(() =>
  import('@leagueplatform/claims').then((module) => {
    const claimsConfig = getClaimsConfig();
    module.leagueClaims.config(claimsConfig);

    return { default: module.ClaimsRoutes };
  }),
);

export const HealthJourneyRoutes = React.lazy(() =>
  import('@leagueplatform/health-journey').then((module) => ({
    default: module.HealthJourneyRoutes,
  })),
);

export const MessagingRoutes = React.lazy(() =>
  import('@leagueplatform/messaging').then((module) => ({
    default: module.MessagingRoutes,
  })),
);

export const HomePage = React.lazy(() =>
  import('pages/home/home.page').then((module) => ({
    default: module.HomePage,
  })),
);

export const SupportPage = React.lazy(() =>
  import('@leagueplatform/highmark-common').then((module) => ({
    default: module.SupportPage,
  })),
);

export const LiveChatPage = React.lazy(() =>
  import('@leagueplatform/live-chat').then((module) => {
    // Config init is done here to stop the main App.tsx init from importing this SDK
    // which would cause the SDK to be bundled into the main chunk
    module.leagueLiveChat.config({
      liveChatClient: createLiveChatClient({ env: import.meta.env.VITE_ENV }),
    });
    return {
      default: module.LiveChatRoutes,
    };
  }),
);
