import * as React from 'react';

import { Box, BoxProps } from '@leagueplatform/genesis-commons';
import { useScrollTopOnLocationChange } from '@leagueplatform/web-common';
import { APP_NAV_DEFAULT_HEIGHT } from '@leagueplatform/highmark-common';
import { AppNavBarComponent } from './nav.component';
import { BeneficityFooter } from './footer/beneficity-footer.component';

type BaseStaticPageProps = BoxProps & {
  isNavEnabled?: boolean;
  isShowFooter?: boolean;
};

export const BaseStaticPage: React.FC<BaseStaticPageProps> = ({
  children,
  isNavEnabled = false,
  isShowFooter = true,
  ...boxProps
}) => {
  useScrollTopOnLocationChange();

  return (
    <>
      {isNavEnabled && <AppNavBarComponent />}
      <Box
        as="main"
        position="relative"
        flex="1"
        overflowX="hidden"
        // This is so any page will take up the whole screen regardless of content
        minHeight={`calc(100vh - ${APP_NAV_DEFAULT_HEIGHT}px)`}
        {...boxProps} // eslint-disable-line
      >
        {children}
      </Box>
      {isShowFooter && <BeneficityFooter />}
    </>
  );
};
