import React from 'react';
import { TENANT_ID } from 'common/constants';
import { PrivateBasePage } from 'components/private-base-page.component';
import {
  ERROR_TYPES,
  ErrorState,
  INELIGIBLE_CODES,
  LoadingSpinner,
  MfaEnrollment,
  PageEligibilityContext,
  useAnalyticsIdentify,
  useFetchAndActivate,
  usePrivateRoutesContainer,
} from '@leagueplatform/highmark-common';
import { RewardsEventController } from '@leagueplatform/rewards-events';
import {
  ACHIEVEMENTS_ENABLED,
  REWARDS_EVENTS_ENABLED,
} from '@leagueplatform/rewards-api';
import { useFeatureFlagQuery } from '@leagueplatform/user-profile-api';
import { WalkthroughContainer } from './app-walkthrough/walkthrough-container.component';
import { firebaseRemoteConfig } from '../common/firebase';

export const PrivateRoutesContainer = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const {
    contentConfig,
    eligibilityInfo,
    showWalkthrough,
    onCompleteWalkthrough,
    showMfa,
    onCompleteMfa,
    isLoading,
    isErrorAppInitDetails,
    isErrorUserProfile,
  } = usePrivateRoutesContainer();

  useFetchAndActivate({ remoteConfig: firebaseRemoteConfig });

  const { data: isRewardsLayerEnabled } = useFeatureFlagQuery(
    REWARDS_EVENTS_ENABLED,
  );

  const { data: isAchievementsEnabled } =
    useFeatureFlagQuery(ACHIEVEMENTS_ENABLED);

  // Send user data to analytics SDK
  useAnalyticsIdentify(TENANT_ID);

  if (isLoading) return <LoadingSpinner />;

  if (isErrorAppInitDetails) {
    return <ErrorState isFullPage errorType={ERROR_TYPES.API_ERROR} />;
  }

  // Show not eligible screen if user isn't eligible to use the app
  if (!eligibilityInfo.eligible) {
    // Show specific not eligible screen if user hasn't had coverage for over 365 days
    if (eligibilityInfo.reasonCode === INELIGIBLE_CODES.OVER_365_DAYS) {
      return <ErrorState isFullPage errorType={ERROR_TYPES.OVER_365_DAYS} />;
    }
    // Otherwise, show generic not eligible screen
    return <ErrorState isFullPage errorType={ERROR_TYPES.NOT_ELIGIBLE} />;
  }

  // Show error screen if user is eligible, but userProfile API fails
  if (isErrorUserProfile)
    return <ErrorState isFullPage errorType={ERROR_TYPES.API_ERROR} />;

  // Show walkthrough slides if needed
  if (showWalkthrough) {
    return (
      <WalkthroughContainer onCompleteWalkthrough={onCompleteWalkthrough} />
    );
  }

  if (showMfa) {
    return <MfaEnrollment onEnrollSuccess={onCompleteMfa} />;
  }

  return (
    <PrivateBasePage>
      {isRewardsLayerEnabled && isAchievementsEnabled && (
        <RewardsEventController />
      )}
      <PageEligibilityContext.Provider value={contentConfig}>
        {children}
      </PageEligibilityContext.Provider>
    </PrivateBasePage>
  );
};
