import League, {
  ENVIRONMENT,
  LEAGUE_MODULE_NAMES,
  LeagueConfig,
} from '@leagueplatform/core';
import {
  AUTH0_CLIENT_CONNECTION,
  LinkHandler,
  LoadingSpinner,
  highmarkCommon,
} from '@leagueplatform/highmark-common';
import {
  TENANT_ID,
  APP_STORE_PROD_URL,
  PLAY_STORE_PROD_URL,
} from 'common/constants';
import { GroupLogo } from 'components/group-logo.component';

import { formsLibrary } from '@leagueplatform/engen-capability-forms-web';
import { ASSETS_CONFIG } from './common/asset-config';
import { EN_STRING_OVERRIDES } from './locales/sdk-string-overrides/en';
import { theme } from './theme/theme';

const formsLibraryApiUrl = import.meta.env.VITE_HIGHMARK_REST_API.replace(
  '/digital',
  '',
);

export const config: LeagueConfig = {
  core: {
    tenantId: TENANT_ID,
    auth: {
      clientOptions: {
        domain: import.meta.env.VITE_AUTH0_DOMAIN as string,
        client_id: import.meta.env.VITE_AUTH0_CLIENT_ID as string,
        redirect_uri: window.location.origin,
        connection: import.meta.env.VITE_AUTH0_CLIENT_CONNECTION_OVERRIDE
          ? import.meta.env.VITE_AUTH0_CLIENT_CONNECTION_OVERRIDE
          : AUTH0_CLIENT_CONNECTION,
        // if we're on testprod we need to set an audience value
        ...(import.meta.env.VITE_AUTH0_AUDIENCE
          ? { audience: import.meta.env.VITE_AUTH0_AUDIENCE }
          : null),
      },
      // Number of hours for which a session can be inactive before user is prompted to log out/continue
      // Note: Any changes to this value have to be synced with the idle_session_lifetime in the auth0 configuration.
      idleSessionLifetimeHours: 0.25,
    },
    api: {
      wsUrl: import.meta.env.VITE_API_URL as string,
      url: import.meta.env.VITE_REST_API_URL as string,
      legacyRestApi: import.meta.env.VITE_LEGACY_REST_API as string,
    },
    contentUrl: import.meta.env.VITE_CONTENT_SERVER_URL as string,
    appEnvironment: import.meta.env.VITE_ENV as ENVIRONMENT,
    analytics: {
      segmentKey: import.meta.env.VITE_SEGMENT,
    },
    customMethods: {
      handleLink: LinkHandler.handleLink,
    },
    routing: {
      isUsingLeagueRouting: true,
    },
    mobileAppUrls: {
      appStoreUrl: APP_STORE_PROD_URL,
      playStoreUrl: PLAY_STORE_PROD_URL,
    },
    i18n: {
      stringOverrides: {
        default: EN_STRING_OVERRIDES,
        en: EN_STRING_OVERRIDES,
      },
    },
    ui: {
      theme,
      components: {
        loading: { element: <LoadingSpinner />, text: false },
        pageHeader: {
          shape: 'line',
          backgroundColor: 'surface.background.secondary',
        },
      },
      groupLogo: { element: <GroupLogo /> },
    },
  },
  assets: {
    overrides: ASSETS_CONFIG,
  },
};

// These are SDK initializers that have not yet been migrated to the new Config API.
export const initializeNonConfigSDKs = () => {
  League.overwriteRoutePaths({
    [LEAGUE_MODULE_NAMES.wallet]: '/benefits',
    [LEAGUE_MODULE_NAMES.careDiscovery]: '/care-discovery',
  });

  highmarkCommon.config({
    highmarkContentUrl: import.meta.env.VITE_HIGHMARK_CONTENT_URL,
    highmarkMemberPortalUrl: import.meta.env.VITE_HIGHMARK_MEMBER_PORTAL_URL,
    highmarkRestApiUrl: import.meta.env.VITE_HIGHMARK_REST_API,
    highmarkSsoAppUrl: import.meta.env.VITE_HIGHMARK_SSO_APP_URL,
  });

  formsLibrary.config({
    HIGHMARK_API_URL: formsLibraryApiUrl,
    HIGHMARK_CDN_URL: import.meta.env.VITE_HIGHMARK_CONTENT_URL,
    LOCAL_URL: 'https://app.hm-beneficity.local.league.dev:3000',
    STAGING_URL: 'https://league-hm-firebase-beneficity-staging.league.dev',
  });
};
