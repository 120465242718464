import React from 'react';
import {
  LoadingSpinner,
  useGetAppInitDetails,
  useIsAuthenticated,
} from '@leagueplatform/highmark-common';
import { BaseStaticPage } from '../../components/base-static-page.component';

export const StaticPageWrapper = ({
  children,
}: {
  children: JSX.Element | JSX.Element[];
}) => {
  const { isAuthenticated } = useIsAuthenticated();
  const { eligibilityInfo } = useGetAppInitDetails();

  // Show loading spinner while loading data
  if (
    isAuthenticated === undefined ||
    (isAuthenticated && eligibilityInfo === undefined)
  )
    return <LoadingSpinner />;

  // Don't show navigation if the user isn't logged in or isn't eligible to access the app
  return (
    <BaseStaticPage isNavEnabled={isAuthenticated && eligibilityInfo.eligible}>
      {children}
    </BaseStaticPage>
  );
};
