import {
  APP_MANAGED_ROUTES,
  FooterLinkProps,
  useGetAppInitDetails,
  useIsAuthenticated,
  FEATURE_FLAGS,
  AUTHED_WEBVIEW_URL_NAMES,
  getAuthedWebviewUrl,
} from '@leagueplatform/highmark-common';
import { useIntl } from '@leagueplatform/locales';
import { useConfigProperty } from '@leagueplatform/core';
import { useGetAppFeatureFlag } from './use-get-app-feature-flag.hook';

export const useFooterLinks = () => {
  const { formatMessage } = useIntl();
  const { isAuthenticated } = useIsAuthenticated();
  const { eligibilityInfo } = useGetAppInitDetails({
    enabled: !!isAuthenticated,
  });

  const { isEnabled: isLegalPageEnabled } = useGetAppFeatureFlag(
    FEATURE_FLAGS.LEGAL_PAGE,
  );
  const legalUrl = APP_MANAGED_ROUTES.LEGAL;

  const legalSsoUrl = getAuthedWebviewUrl(AUTHED_WEBVIEW_URL_NAMES.LEGAL);

  const isPostAuth = isAuthenticated && eligibilityInfo?.eligible;

  const handleLink = useConfigProperty('core.customMethods.handleLink');

  // only show authentication-required links if the user is logged in and eligible to access the app
  const footerLinks: FooterLinkProps[] = isPostAuth
    ? [
        {
          text: formatMessage({ id: 'LEGAL' }),
          onClick: !isLegalPageEnabled
            ? () => handleLink(legalSsoUrl)
            : undefined,
          url: isLegalPageEnabled ? legalUrl : legalSsoUrl,
          linkProps: {
            target: '_self',
          },
        },
        {
          text: formatMessage({ id: 'ALL_LANGUAGES' }),
          url: APP_MANAGED_ROUTES.TECHNICAL_ASSISTANCE_AND_ALL_LANGUAGES,
          linkProps: {
            target: '_self',
          },
        },
      ]
    : [
        {
          text: formatMessage({ id: 'NON_DISCRIMINATION_POLICY' }),
          url: '/nondiscrimination-policy-statement.pdf',
        },
      ];

  return { footerLinks };
};
