import * as React from 'react';
import {
  FEATURE_FLAGS,
  Navbar,
  useGetAppInitDetails,
  useInjectCobrowseScript,
} from '@leagueplatform/highmark-common';
import { getUserDropdownConfig } from 'utils/get-user-dropdown-config';
import { useGetAppFeatureFlag } from 'hooks/use-get-app-feature-flag.hook';

export const AppNavBarComponent = React.memo(() => {
  const { qualifiers } = useGetAppInitDetails();
  const { isEnabled: isCobrowseEnabled } = useGetAppFeatureFlag(
    FEATURE_FLAGS.USER_DROPDOWN_COBROWSE,
  );
  const { isEnabled: isCoordinationBenefitsV2 } = useGetAppFeatureFlag(
    FEATURE_FLAGS.COB_V2,
  );
  useInjectCobrowseScript(isCobrowseEnabled);
  const userDropdownConfig = getUserDropdownConfig({
    ...qualifiers,
    isCobrowseEnabled,
    isCoordinationBenefitsV2,
  });

  return <Navbar userDropdownConfig={userDropdownConfig} />;
});
