import {
  APP_MANAGED_ROUTES,
  FEATURE_FLAGS,
  LegalPage,
} from '@leagueplatform/highmark-common';
import { useHistory } from '@leagueplatform/routing';
import { LoadingIndicator } from '@leagueplatform/web-common-components';
import { useGetAppFeatureFlag } from 'hooks/use-get-app-feature-flag.hook';
import { useEffect } from 'react';

export const LegalPageRoutes = () => {
  const { isEnabled, isLoading } = useGetAppFeatureFlag(
    FEATURE_FLAGS.LEGAL_PAGE,
  );
  const history = useHistory();

  useEffect(() => {
    if (!isEnabled && !isLoading) {
      history.push(APP_MANAGED_ROUTES.HOME);
    }
  }, [isEnabled, isLoading, history]);

  if (isLoading) {
    return <LoadingIndicator />;
  }

  return isEnabled ? <LegalPage /> : null;
};
